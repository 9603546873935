import { shopifyPlanComparison } from "assets/data/plans/shopifyPlans";
import { FC } from "react";
import { TSelectedPlan } from "types/plans";
import ShopifyPlanComparisonFooter from "./ComparisonFooter";
import PlansComparisonItem from "./ComparisonItem";

interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isRegFunnel?: boolean;
    isPayNow?: boolean;
    wrapperStyles?: string;
    fromNoSub?: boolean;
    selectedPlan?: "monthly" | "yearly"
}

const ShopifyPlansComparison: FC<Props> = ({ handleSelectPlan, isRegFunnel, isPayNow, wrapperStyles, fromNoSub, selectedPlan }) => {
    const flexStyles = "flex items-center font-bold w-full justify-center"
    return (
        <div className={`max-w-[744px] max-md:max-w-[491px] max-[670px]:max-w-[320px] mx-auto w-full flex justify-center dark:text-darkBlue ${wrapperStyles}`}>
            <div className=" w-full">
                <div className="shadow-shadow3 z-10 rounded-[15px] overflow-hidden relative z-[20]">
                    <div className={`h-[49px] grid grid-cols-3 max-[670px]:grid-cols-2 rounded-t-[15px] bg-darkBlue text-white flex justify-between`}>
                        <div className={`${flexStyles} `}>Quick Look</div>
                        <div className={`${flexStyles} ${selectedPlan === "yearly" ? "max-[670px]:hidden" : ""}`}>Monthly</div>
                        <div className={`${flexStyles}  ${selectedPlan === "monthly" ? "max-[670px]:hidden" : ""}`}>Yearly</div>
                    </div>
                    {shopifyPlanComparison.map((item, i, row) => (
                        <PlansComparisonItem
                            key={item.id}
                            hideBottomBorder={i + 1 === row.length}
                            {...item}
                        />
                    ))}
                </div>
                <ShopifyPlanComparisonFooter fromNoSub={fromNoSub} isRegFunnel={isRegFunnel} handleSelectPlan={handleSelectPlan} isPayNow={isPayNow} selectedPlan={selectedPlan} />
            </div>
        </div>
    )
}

export default ShopifyPlansComparison