import { useState } from "react";
import StepOne from "./steps/StepOne";
import StepThree from "./steps/StepThree";
import StepTwo from "./steps/StepTwo";

const ShopifyAffiliateFunnel = () => {
    const [step, setStep] = useState(1);
    const handleStepChange = (step: number) => {
        setStep(step);
    }
    return (
        <>
            <div className="flex min-h-full relative">
                <img src="https://zikmedia.s3.amazonaws.com/current_zik_dashboard.svg" alt="ZIK Dashboard" className="min-h-screen min-w-screen bg-cover w-full max-lg:hidden" />
                <div className="absolute top-0 left-0 w-full h-full bg-[#808080]/30"></div>
            </div>
            <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center">
                {step === 2 ? <StepTwo handleStepChange={handleStepChange} /> : step === 3 ? <StepThree handleStepChange={handleStepChange} /> : <StepOne handleStepChange={handleStepChange} />}

            </div>
        </>
    )
}

export default ShopifyAffiliateFunnel