import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Rocket from "assets/images/rocketemoji.png";
import ShopifyBag from "assets/images/shopifybag.png";
import useDecodeToken from "hooks/useDecodeToken";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useShopifyStoreStatusMutation } from "store/api/onboardingApi";
import ShopifyAffiliateActionButton from "../components/button";


interface Props {
    handleStepChange?: (step: number) => void;
}

const StepTwo = ({ handleStepChange }: Props) => {
    const navigate = useNavigate();
    const userDetails = useDecodeToken();
    const firstName = userDetails.unique_name.split(" ")[0] || ""
    const [postShopifyStatus, { data, isLoading }] = useShopifyStoreStatusMutation();
    const handleClick = async (id: number) => {
        postShopifyStatus(id).unwrap().then(data => {
            if (data.message === "AlreadyHadShopifyStore") {
                navigate(PATH.shopify.marketInsights)
            }
            if (data.message === "NotHaveShopifyStore") {
                window.open("http://shopify.pxf.io/vPnyBy");
                handleStepChange?.(3)
            }


        });
    }
    return (
        <div className="shadow-2xl bg-white dark:bg-grey800 rounded-10 w-full dark:text-grey100 max-w-[910px] flex flex-col  max-lg:max-w-full max-lg:h-full max-lg:rounded-none max-lg:p-0 max-lg:px-5 lg:max-h-[40rem] xl:max-h-[47rem] lg:justify-center ">
            <div className="flex flex-col items-center justify-center pt-[17px] pb-[64px] px-[27px]">
                <div className="flex justify-end w-full">
                    <div className="w-[84px] h-[32px] flex items-center justify-center bg-neutral2 rounded-full"><Shopify className="w-[65px] h-auto" /></div>
                </div>
                <div className="text-[20px] lieading-[28px] my-4 max-sm:text-[18px]">Hi {firstName}!</div>
                <h6 className="font-bold text-[20px] leading-[28px] mb-[24px] text-center max-sm:text-[18px]">Do you already have a Shopify store?</h6>

                <div className="flex justify-center items-center gap-[32px] w-full max-md:flex-col">
                    <ShopifyAffiliateActionButton title="I have a Shopify store" altTitle="Continue to ZIK" icon={Rocket} handleClick={handleClick} id={1} isLoading={isLoading} />
                    <ShopifyAffiliateActionButton title="I don’t have a Shopify store" altTitle="Create a store for $1 Month" icon={ShopifyBag} handleClick={handleClick} id={2} isLoading={isLoading} />
                </div>


            </div>
        </div>
    )
}

export default StepTwo