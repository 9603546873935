import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import Tooltip from "components/Tooltip";
import { FC, ReactNode } from "react";

interface RowProps {
  title: string;
  subtitle: string | ReactNode;
  limit?: string;
  tooltipTitle?: string;
  Icon?: ReactNode;
  isNew?: boolean;
  isUnlimited?: boolean;
}
export const FeaturesRow: FC<RowProps> = ({ title, subtitle, limit, tooltipTitle, Icon, isNew, isUnlimited }) => {
  return (
    <div className={`flex flex-col text-sm ${isNew ? "bg-neutral1 border border-solid border-grey100 p-[7px] pl-[0px] rounded-[8px]" : ""}`}>
      <div className={`flex items-center ${limit ? "mb-2" : ""}`}>
        <div className="min-w-[30px] h-[30px] flex items-center justify-center mr-[5px]">
          {Icon || <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />}
        </div>
        <div className={`w-full`}>
          <div className="flex items-center justify-between w-full mb-1">
            <div className="flex items-center">
              <div className="font-bold">{title}</div>
              {tooltipTitle && !isUnlimited ? (
                <div className="h-3">
                  <Info className={"info-icon w-3 h-3 ml-2 cursor-pointer"} data-tooltip-id={title + limit} />
                  <Tooltip title={tooltipTitle} anchorId={title + limit} className="text-center" maxWidth={"max-sm:max-w-[200px]"} />
                </div>
              ) : ""}
              {/* <InfoTooltip title={tooltipTitle} iconClassName="w-3 h-3" className="ml-2" maxWidth="max-w-[100%]" /> */}
            </div>
            {isNew && (
              <div className="bg-[#C93A1B] text-white font-medium h-5 leading-[20px] text-xs w-[46px] text-center rounded-[4px] ml-2">New!</div>
            )}
          </div>
          {limit ? (
            <>
              <p className="text-xs font-medium text-primaryPurple ml-[auto]">{isUnlimited ? "Unlimited" : limit}</p>
            </>
          ) : ""}
        </div>
      </div>
      <div className="text-xs ml-[35px]">{subtitle}</div>
    </div>
  )
}
interface Props {
  isProPlus?: boolean;
  isProTools: boolean;
}

const ProPlus: FC<Props> = ({ isProPlus, isProTools }) => (
  <div className="flex flex-col gap-4 pt-[2px]">
    <div className="font-medium text-sm px-2">
      All PRO{isProPlus ? "" : "+"} plan features, and:
    </div>
    {isProTools && !isProPlus ? "" :
      <>
        <FeaturesRow title="500 Best Selling eBay Items" subtitle="Find all the best 500 items selling on eBay" />
        <FeaturesRow title="Bulk Scanner" subtitle="Instantly find thousands of Amazon and Walmart dropship items" />
        <FeaturesRow title="Ali Growth Scanner" subtitle="Easily gather data on trends coming from AliExpress" />
      </>
    }
    <FeaturesRow title="Autopilot" subtitle="Automatically find dropshipping products from AliExpress, Amazon, and Walmart"
      limit="10 scans / month" tooltipTitle="Limited to 10 scans a month. Upgrade later for Unlimited!" isUnlimited={!isProPlus}
    />
    <FeaturesRow title="Turbo Scanner" subtitle="Automatically discover the best selling dropship items from Amazon and Walmart"
      limit="10 scans / month" tooltipTitle="Limited to 10 scans a month. Upgrade later for Unlimited!" isUnlimited={!isProPlus}
    />
    <FeaturesRow title="Product Explorer" subtitle="Find best selling eBay products in seconds"
      limit="100 searches / month" tooltipTitle="Limited to 100 searches a month. Upgrade later for Unlimited!"
      Icon={<ProductExplorer className="fill-primaryPurple dark:fill-purple500 w-[18px] h-[19px]" />} isNew isUnlimited={!isProPlus}
    />
  </div>
);

export default ProPlus;
