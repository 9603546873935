import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as Check } from "assets/icons/checkmark.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import { PlanComparisonItemType } from "types/plans";


interface Props {
  item: PlanComparisonItemType;
  hasBottomDivider: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  showTwoPlans?: boolean;
}
const sameIconStyles = "fill-primaryPurple dark:fill-purple500 w-[14px]"
export const getComparisonIcon = (icon: string) => {
  switch (icon) {
    case "dashboard":
      return <Logo className="w-[14px]" />;
    case "product":
      return <ProductResearch className="w-3 fill-primaryPurple dark:fill-purple500" />;
    case "competitor":
      return <CompetitorResearch className={sameIconStyles} />;
    case "category":
      return <CategoryResearch className={sameIconStyles} />;
    case "titleBuilder":
      return <TitleBuilder className={sameIconStyles} />;
    case "itemFinder":
      return <ItemFinder className={sameIconStyles} />;
    case "productExplorer":
      return <ProductExplorer className={sameIconStyles} />
    case "proTools":
      return <ZikPro className={sameIconStyles} />;
    default:
      return null;
  }
};

const includeInPlan = (include: boolean | string) =>
  typeof include === "string" ? <p className={`font-normal max-lg:text-sm text-center block max-xl:mx-2 ${include.toLowerCase().includes("unlimited") ? "text-primaryPurple" : ""}`}>{include}</p> : (
    <div className={`${include ? "bg-smoothGreen" : "bg-secondary"} w-6 h-6 rounded-full flex justify-center items-center`}>
      {include ? (
        <Check className="fill-white" />
      ) : (
        <Close className="fill-white" />
      )}
    </div>
  );
const colSameStyles = "bg-white flex justify-center items-center"
const PlansComparisonItem = ({ item, selectedPlan, hasBottomDivider, showTwoPlans }: Props) => (
  <div className="bg-white">
    <div className={`grid  max-lg:grid-cols-5 ${showTwoPlans ? "grid-cols-3" : "grid-cols-4"}`}>
      <div className={`bg-white p-2 max-lg:col-span-3 border-r-2 border-secondary ${hasBottomDivider ? "" : "pb-[15px]"}`}>
        <div className="flex items-center gap-[5px] mb-[5px]">
          <div className="w-6 h-6 flex justify-center items-center">
            <div>{getComparisonIcon(item.icon)}</div>
          </div>
          <div className="text-sm font-bold text-darkBlue">
            {item.title}
          </div>
        </div>
        <div
          className="text-xs font-medium"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>

      <div className={`${colSameStyles} lg:border-r-2 lg:border-secondary max-lg:col-span-2 ${selectedPlan === "pro" ? "" : "max-lg:hidden"}`}>
        {includeInPlan(item.pro)}
      </div>
      <div className={`${colSameStyles} lg:border-r-2 lg:border-secondary  max-lg:col-span-2 ${selectedPlan === "pro-plus" ? "" : "max-lg:hidden"}`}>
        {includeInPlan(item.proPlus)}
      </div>
      {!showTwoPlans ? (
        <div className={`${colSameStyles} max-lg:col-span-2 ${selectedPlan === "enterprise" ? "" : "max-lg:hidden"}`}>
          {includeInPlan(item.enterprise)}
        </div>
      ) : ""}
    </div>
    {hasBottomDivider && (
      <div className="h-[2px] bg-secondary w-full" />
    )}
  </div>
);

export default PlansComparisonItem;
