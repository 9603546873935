import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import SwiperWrapper from "components/SwiperWrapper";
import Trusted from "pages/RegistrationFunnel/components/Trusted";
import ShopifyPlansComparison from "pages/Shopify/Billing/components/plans/Comparison";
import ShopifyPlansCont from "pages/Shopify/Billing/components/plans/PlansContainer";
import { FC, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { TSelectedPlan } from "types/plans";
interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    fromNoSub?: boolean;
    isRegFunnel?: boolean;
    hideTrustpilot?: boolean;
    showComparisonOnMobile?: boolean;
}

const SlidedShopifyWithComparison: FC<Props> = ({ handleSelectPlan, hideTrustpilot, isPayNow, fromNoSub, showComparisonOnMobile, isRegFunnel }) => {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const comparisonProps = {
        handleSelectPlan,
        fromNoSub,
        isPayNow

    }
    return (
        <div>
            <ShopifyPlansCont
                handleSelectPlan={handleSelectPlan}
                isRegFunnel={isRegFunnel}
                isPayNow={isPayNow}
                controller={firstSwiper}
                setController={setSecondSwiper}
                isResponsive
            />
            {!hideTrustpilot && (
                <Trusted trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white' wrapperClassName="max-lg:pb-6" />
            )}
            <div>
                <div className={`hidden ${showComparisonOnMobile ? "max-[670px]:block" : ""}`}>
                    <SwiperWrapper
                        controller={secondSwiper}
                        setController={setFirstSwiper}
                    >

                        {shopifyPlans.map((plan) => (
                            <SwiperSlide className="max-w-[320px]" content="fit" key={plan.id}>
                                <ShopifyPlansComparison
                                    {...comparisonProps}
                                    selectedPlan={plan.id as "monthly" | "yearly"}
                                />
                            </SwiperSlide>
                        ))}
                    </SwiperWrapper>
                </div>
                <div className="max-[986px]:hidden">
                    <ShopifyPlansComparison {...comparisonProps} />
                </div>
            </div>
        </div>
    )
}

export default SlidedShopifyWithComparison;