import { PlanList, PlansType, TSelectedPlan } from "types/plans";
import NextPrevSlider from "./NextPrevSlider";
import Plan from "./Plan";

import { SwiperSlide } from "swiper/react";

import SwiperWrapper from "components/SwiperWrapper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";


interface Props {
    plans: PlansType;
    plansWrapperClassName?: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    handleProToolUpgrade?: () => void;
    fromRegistration?: boolean;
    buttonFilled?: boolean;
    selectedPlan?: "pro" | "pro-plus" | "enterprise";
    isPayNow?: boolean;
    nextPrevStyles?: string;
}


const SlidedPlans = ({
    plans,
    fromRegistration = false,
    plansWrapperClassName,
    handleSelectPlan,
    handleProToolUpgrade,
    buttonFilled,
    isPayNow,
    nextPrevStyles,
}: Props) => {
    const plansProps = (plan: PlanList) => ({
        plan,
        handleSelectPlan,
        handleProToolUpgrade,
        fromRegistration,
        isPayNow,
        buttonFilled,
    })
    return (
        <div className="w-full">
            <div className={`flex justify-between w-full max-md:hidden mx-auto max-md:px-2 ${plansWrapperClassName || ""}`}>
                {plans.planList.map((plan, i) => (
                    <Plan
                        {...plansProps(plan)}
                        key={i}
                    />

                ))}
            </div>
            <div
                className={`w-full md:hidden ${plansWrapperClassName || ""}`}
            >
                <SwiperWrapper>
                    {plans.planList.map((plan, i) => (
                        <SwiperSlide className="max-w-[350px]" content="fit" key={plan.planId}>
                            <Plan
                                key={i}
                                {...plansProps(plan)}
                            />
                        </SwiperSlide>

                    ))}
                    <NextPrevSlider wrapperStyles={nextPrevStyles} />
                </SwiperWrapper>
            </div>
        </div>)
};

export default SlidedPlans;
