import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Present from "assets/images/present.png";
import Button from "components/Button";

interface Props {
  handleStepChange?: (step: number) => void;
}

const StepOne = ({ handleStepChange }: Props) => {
  return (
    <div className="shadow-2xl bg-white dark:bg-grey800 rounded-10 w-full dark:text-grey100 max-w-[641px] flex flex-col  max-lg:max-w-full max-lg:h-full max-lg:rounded-none max-lg:p-0 max-lg:px-5 lg:max-h-[40rem] xl:max-h-[47rem] lg:justify-center ">
      <div className="flex flex-col items-center justify-center pt-[31px] pb-[41px]">
        <h5 className="text-center mb-[24px] text-[18px] font-bold">
          You are all set!
        </h5>
        <img src={Present} alt="" className="w-10 h-10 mb-[39px]" />


        <h6 className="font-bold text-[24px] leading-[32px] mb-[16px]">Payment Completed</h6>
        <div className="bg-smoothGreen rounded-full w-[35px] h-[35px] flex justify-center items-center mb-[39px]">
          <Checkmark className="w-[23.33px] h-[17.5px] fill-white" />
        </div>


        <Button

          title="Next"
          className="max-w-[232px] hover:text-white hover:bg-darkBlue"
          titleClassName="!font-bold !text-base"
          height="h-[60px]"
          handleClick={() => { handleStepChange?.(2) }}
        />
      </div>
    </div>
  )
}

export default StepOne