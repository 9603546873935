import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { PlanList, TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import { getPlanSuffix, isDowngrade, isUpgrade } from "utils/planUtils";

interface Props {
    plan: PlanList;
    period: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    fromRegistration?: boolean;
    selectedPlan?: "pro" | "pro-plus" | "enterprise";
    isPayNow?: boolean;
    showTwoPlans?: boolean;
}
interface CurrentPlanProps {
    plan: PlanList;
    isTrial?: boolean;
}


const CurrentPlan: FC<CurrentPlanProps> = ({ plan, isTrial, }) => {
    return (
        <div className="relative -top-[40px] max-lg:max-w-[320px] max-lg:mx-auto">
            <div className={`bg-secondary text-center ${isTrial ? "px-[30px]" : "px-[61px]"} pt-[68px] pb-[31px] rounded-b-[15px] text-sm font-medium w-full`}>
                {isTrial ? "This is your current $1 Trial plan" : "This is your Current Plan"}
            </div>
            {plan.type.includes("Enterprise") ? (
                <div className="absolute bg-secondary  w-full h-[50px] bottom-[100%] left-[-2px]" />
            ) : ""}
        </div>
    )

}

const PlanFooterItem: FC<Props> = ({ plan, period, fromRegistration, handleSelectPlan, isPayNow, showTwoPlans }) => {
    const user = useDecodeToken();
    const isTrial = /^true$/i.test(user.active_trail);
    const currentPlan = user.package_name || "";
    const isMonthly = period === "monthly";

    const showDarkBlue = (plan: string) => (
        user.package_name === "StandardM" && plan === "EnterpriseM") ||
        (user.package_name === "StandardY" && plan === "EnterpriseY") ||
        (user.package_name === "ProPlusLT" && plan === "EnterpriseLT");
    const showCurrent = plan.type === currentPlan && !isPayNow;
    const showUpgrade = isUpgrade(currentPlan || "", plan.type);
    const showDowngrade = isDowngrade(currentPlan || "", plan.type) && !isPayNow;
    const hideDiscounted = !plan.isDiscount || ((!showUpgrade || showCurrent) && isMonthly && !isTrial) || period === "lifetime";

    return showDowngrade ? <div className="h-10" ></div> :
        showCurrent ? <CurrentPlan plan={plan} isTrial={isTrial} /> : (
            <div className={`${plan.highlight || showDarkBlue(plan.type) ? "max-lg:border-2 max-lg:border-t-0 max-lg:border-smoothGreen" : ""}
                 max-lg:rounded-b-[15px] max-lg:max-w-[320px] mx-auto w-full max-[430px]:w-[calc(100%-20px)]`}>
                <div
                    className={`${plan.highlight || showDarkBlue(plan.type) ? "bg-darkBlue text-white" : "bg-secondary text-darkBlue"}  
                  ${(plan.type.includes("Standard") || plan.type.includes("ProPlus")) && "border-[2px] border-t-0 border-smoothGreen"
                        } rounded-b-[13px] p-[14px] lg:mx-auto relative`}
                >
                    <div className={`flex justify-between items-center`}>
                        <div className="">
                            <div className="flex justify-between items-center">
                                <div className={`text-center font-bold text-xl uppercase`}>
                                    {plan.planName}
                                </div>
                            </div>

                            <div className={"font-normal text-lg flex"}>
                                {period === "yearly" ?
                                    <span>
                                        <span className="font-bold">
                                            {formatCurrency(plan.planDiscountPrice)}</span></span> :
                                    <span>{formatCurrency((isMonthly && (fromRegistration || isTrial || isPayNow)) || period === "lifetime" ? plan.planDiscountPrice : plan.planPrice)}
                                    </span>}
                                <span>{period === "yearly" ? "/mo" : getPlanSuffix(plan.type)}</span>
                            </div>
                        </div>
                        <div>
                            {plan.highlight && (
                                <span className={`min-w-[97px] w-full capitalize text-xs rounded-[4px] font-medium bg-electricBlue text-center py-1.5 h-fit mb-1 block`}>
                                    most popular
                                </span>
                            )}
                            {!hideDiscounted ? (
                                <div>
                                    {isMonthly ? (
                                        <p className={`font-bold ${plan.highlight ? "text-smoothGreen" : "text-[#24921A]"}`}>50% Off</p>
                                    ) : ""}
                                    <div className={`flex items-center font-medium text-sm ${plan.highlight ? "text-white" : "text-darkBlue"}`}>
                                        {isMonthly ? <span className={`${plan.highlight ? "hidden" : ""}`}>for 1st month</span> : (
                                            <>
                                                <span className="text-[#FF6A00]">Save ${Math.round(plan.planDiscountPrice && (plan.planPrice - plan.planDiscountPrice) * 12)}!</span>
                                                <div className="flex w-[22px] justify-center">
                                                    <Checkmark className="fill-smoothGreen w-[11px] h-[8px]" />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : ""}
                        </div>
                    </div>


                    {showUpgrade || isPayNow || fromRegistration || isTrial ? (
                        <div>
                            <Button
                                className={`!bg-white hover:!bg-smoothGreen text-darkBlue font-bold mt-4 flex w-full mx-auto`}
                                height="h-11"
                                color="smoothGreen"
                                titleClassName={`!font-medium group-hover:text-white !text-base dark:text-darkBlue`}
                                title={
                                    fromRegistration
                                        ? "Start $1 Trial Now"
                                        : isTrial
                                            ? "Change"
                                            : isPayNow
                                                ? "Pay now"
                                                : "Upgrade now"
                                }
                                handleClick={() => {
                                    handleSelectPlan?.({
                                        type: plan.type,
                                        planName: plan.planName,
                                        planPrice: plan.planPrice,
                                        skip: false
                                    });
                                }}
                            />
                            {fromRegistration && <div className="flex justify-center items-center ">
                                <button
                                    className={`text-xs cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 underline mt-2 leading-[16px] ${plan.highlight ? "text-smoothGreen" : "text-electricBlue"}`}
                                    onClick={() => {
                                        handleSelectPlan?.({
                                            type: plan.type,
                                            planName: plan.planName,
                                            planPrice: plan.planPrice,
                                            skip: true
                                        });
                                    }}
                                >
                                    Skip Trial {plan.skipAmout === 0 ? "Now!" : `and Save ${formatCurrency(plan.skipAmout || 0)}`}
                                </button>
                            </div>}
                        </div>
                    ) : null}
                    <div className={`absolute w-full h-[50px] bottom-[100%] left-0 ${showTwoPlans ? "bg-darkBlue" : "bg-secondary"}`} />
                </div>
            </div>
        )
}

export default PlanFooterItem;