import { ReactComponent as Check } from "assets/icons/checkmark.svg";
import { FC } from "react";

interface Props {
    Icon: any;
    title: string;
    desc: string;
    hideBottomBorder: boolean;
    width: number;
    height: number;
}

interface CellProps {
    hideBorder?: boolean;
    wrapperStyle?: string;
}
const CheckmarkCell: FC<CellProps> = ({ hideBorder, wrapperStyle }) => {
    return (
        <div className={`bg-white flex justify-center items-center w-full ${wrapperStyle} ${hideBorder ? "" : "border-r-2 border-secondary"}`}>
            <div className="w-6 h-6 bg-smoothGreen dark:bg-paradiseBlue rounded-full flex justify-center items-center">
                <Check className="fill-white w-3 h-[9px]" />
            </div>
        </div>
    )
}

const PlansComparisonItem: FC<Props> = ({ Icon, title, desc, hideBottomBorder, width, height }) => {
    return (
        <div className="bg-white">
            <div className="grid grid-cols-3 max-[670px]:grid-cols-2">
                <div className={`bg-white p-2 w-full border-r-2 border-secondary ${hideBottomBorder ? "pb-[15px]" : ""}`}>
                    <div className="flex items-center gap-[5px] mb-[5px]">
                        <div className="w-6 h-6 flex justify-center items-center">
                            <Icon className="fill-primaryPurple" width={width} height={height} />
                        </div>
                        <div className="text-sm font-bold text-darkBlue">
                            {title}
                        </div>
                    </div>
                    <p className="text-xs font-medium leading-[16px]">{desc}</p>
                </div>
                <CheckmarkCell wrapperStyle="max-[670px]:hidden" />
                <CheckmarkCell hideBorder />
            </div>
            {hideBottomBorder ? "" : (
                <div className="h-[2px] bg-secondary w-[100%]" />
            )}
        </div>
    )
}

export default PlansComparisonItem