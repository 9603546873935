import BillingBackButton from "components/Plans/BackButton";
import PlansDiscountTxt from "components/Plans/PlansDiscountTxt";
import PlanSwitch from "components/Plans/PlanSwitch";
import SlidedPlansWithComparison from "components/Plans/SlidedPlansWithComparison";
import SlidedShopifyWithComparison from "components/Plans/SlidedShopifyWithComparison";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import LoadingPage from "pages/Loading";
import SelectMarketplace from "pages/Shopify/Billing/components/SelectMarketplace";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { TSelectedPlan } from "types/plans";
interface Props {
    paymentStatus?: number;
    handleSelectPlan: (newVal: TSelectedPlan) => void;
}
const Upgrade = ({
    paymentStatus,
    handleSelectPlan,
}: Props) => {
    const { search } = useLocation();
    const isShopifyActive = search.includes("marketplace=shopify")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isShopifyActive ? "shopify" : "ebay");
    const user = useDecodeToken();
    const { plans, handlePlanChange } = usePlans("yearly");
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab);
    const { data, isLoading } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const currentPlan = user.package_name || "";
    const shopifyIsNotActive = shopifyPlan?.isCanceled || currentPlan === ""
    const shopifyAlreadyTrial = user.shopify_already_trial === "1" || shopifyIsNotActive;
    const ebayPayNow = paymentStatus === 0

    return (
        <LoadingPage loading={isLoading}>
            <div className="flex justify-center flex-col items-center" >
                <BillingBackButton>
                    <div className="flex flex-col gap-4 w-full">
                        <h4 className="text-center text-lg font-medium dark:text-grey100">Pick your Plan</h4>
                        <h6 className="text-center font-bold dark:text-grey100">Select your Marketplace</h6>
                        <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                        <PlansDiscountTxt activePlans={activeTab} period={plans.period} isDashboardLayout />
                    </div>
                </BillingBackButton>
                {activeTab === "ebay" ? (
                    <div className="flex justify-center items-center flex-col w-full">
                        <PlanSwitch
                            activePlan={plans.period}
                            handlePlanChange={handlePlanChange}
                            longSwitchStyles="py-2.5 max-w-[1010px]"
                            shortSwitchStyles="py-6 max-w-[1010px]"
                        />
                        <SlidedPlansWithComparison
                            plans={plans}
                            handleSelectPlan={handleSelectPlan}
                            isPayNow={ebayPayNow}
                        />
                    </div>
                ) : <div className="w-full pt-12">
                    <SlidedShopifyWithComparison isPayNow={shopifyAlreadyTrial} />
                </div>}
            </div>
        </LoadingPage>
    );
};
export default Upgrade;