
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import { FC } from "react";
import { useSwiper } from "swiper/react";

interface Props {
    isShopify?: boolean;
    wrapperStyles?: string;
}
const arrowCont = "flex items-center justify-center w-11 h-11 bg-neutral2 rounded-full hover:bg-primaryPurple group";
const arrowStyle = "fill-darkBlue group-hover:fill-white h-[21px] w-3"
const arrowFilter = "drop-shadow(0px 8px 16px rgba(20, 23, 26, 0.04)) drop-shadow(0px 6px 8px rgba(20, 23, 26, 0.06))"

const NextPrevSlider: FC<Props> = ({ isShopify, wrapperStyles }) => {
    const swiper = useSwiper();

    return (
        <div className={`flex justify-center gap-[56px] w-full py-4 ${wrapperStyles} ${isShopify ? "min-[700px]:hidden" : "lg:hidden"}`}>
            <button className={arrowCont} style={{ filter: arrowFilter }} onClick={() => swiper.slidePrev()}>
                <LeftArrow className={arrowStyle} />
            </button>
            <button className={arrowCont} style={{ filter: arrowFilter }} onClick={() => swiper.slideNext()}>
                <LeftArrow className={`${arrowStyle} rotate-180`} />
            </button>
        </div>
    )
}

export default NextPrevSlider