import SwiperWrapper from 'components/SwiperWrapper';
import Trusted from 'pages/RegistrationFunnel/components/Trusted';
import { useState } from "react";
import { SwiperSlide } from 'swiper/react';
import { PlansType, TSelectedPlan } from "types/plans";
import PlansComparison from "./EbayPlanComparison/PlansComparison";
import NextPrevSlider from "./NextPrevSlider";
import Plan from "./Plan";



interface Props {
    plans: PlansType;
    plansWrapperClassName?: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    handleProToolUpgrade?: () => void;
    fromRegistration?: boolean;
    selectedPlan?: "pro" | "pro-plus" | "enterprise";
    isPayNow?: boolean;
    comparisonContStyles?: string;
    showComparisonOnMobile?: boolean;
}


const SlidedPlansWithComparison = ({
    plans,
    fromRegistration = false,
    plansWrapperClassName,
    showComparisonOnMobile,
    handleSelectPlan,
    handleProToolUpgrade,
    isPayNow,
    comparisonContStyles,
}: Props) => {
    const showTwoPlans = plans.planList.length === 2
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    const sameProps = {
        handleSelectPlan,
        fromRegistration,
        isPayNow,
    }
    const comparisonProps = {
        ...sameProps,
        plans,
        contStyles: comparisonContStyles,
        fromRegistration,
        isPayNow,
    }

    const planList = plans.planList;

    return (
        <div
            className={`w-full ${plansWrapperClassName || ""} max-lg:max-w-[1140px] relative`}
        >
            <div className='lg:hidden'>
                <SwiperWrapper
                    controller={firstSwiper}
                    setController={setSecondSwiper}
                >

                    {planList.map((plan) => (
                        <SwiperSlide className="max-w-[350px]" content="fit" key={plan.planId}>
                            <Plan
                                {...sameProps}
                                plan={plan}
                                handleProToolUpgrade={handleProToolUpgrade}
                            />
                        </SwiperSlide>
                    ))}
                    <NextPrevSlider />
                </SwiperWrapper>
            </div>
            <div className={`flex justify-between w-full max-lg:hidden gap-2.5 mx-auto ${showTwoPlans ? "max-w-[748px]" : "max-w-[1146px]"}`}>
                {planList.map((plan, i) => (
                    <Plan
                        {...sameProps}
                        plan={plan}
                        handleProToolUpgrade={handleProToolUpgrade}
                        key={plan.planId}
                    />
                ))}
            </div>
            <Trusted trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white' />
            <div className={`lg:hidden ${showComparisonOnMobile ? "" : "max-[986px]:hidden"}`}>
                <SwiperWrapper
                    controller={secondSwiper}
                    setController={setFirstSwiper}
                >
                    {planList.map((plan) => (
                        <SwiperSlide className="max-w-[350px]" content="fit" key={plan.planId}>
                            <PlansComparison
                                {...comparisonProps}
                                selectedPlan={plan.planId as "pro" | "pro-plus" | "enterprise"}
                            />
                        </SwiperSlide>
                    ))}
                </SwiperWrapper>
            </div>
            <div className="w-full max-lg:hidden">
                <PlansComparison {...comparisonProps} />
            </div>

        </div>)
};

export default SlidedPlansWithComparison;
