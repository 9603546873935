import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import { Dispatch, FC, SetStateAction } from "react";
import { SwiperSlide } from "swiper/react";
import { TSelectedPlan } from "types/plans";
import ShopifyPlan from "./Plan";

import NextPrevSlider from "components/Plans/NextPrevSlider";
import SwiperWrapper from "components/SwiperWrapper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isRegFunnel?: boolean;
    isPayNow?: boolean;
    isResponsive?: boolean;
    wrapperStyles?: string;
    sliderWrapperStyles?: string;
    contStyles?: string;
    nextPrevStyles?: string;
    planStyles?: string;
    borderColor?: string;
    controller?: any;
    setController?: Dispatch<SetStateAction<any>>
}

const plans = shopifyPlans
const ShopifyPlansCont: FC<Props> = ({ handleSelectPlan, isRegFunnel, isPayNow, planStyles, isResponsive, wrapperStyles, nextPrevStyles, borderColor, controller, setController }) => {

    const samePlanProps = {
        isRegFunnel,
        isPayNow,
        borderColor,
        handleClick: handleSelectPlan
    }
    return (
        <div className={`w-full ${wrapperStyles}`}>
            <div className={`w-full relative overflow-x-hidden mt-6 ${isResponsive ? "min-[671px]:hidden block" : "hidden"} `}>
                <SwiperWrapper
                    controller={controller}
                    setController={setController}
                >
                    {plans.map((item) =>
                        <SwiperSlide className="h-auto max-w-[310px]" content="fit" key={item.id}>
                            <ShopifyPlan {...item}  {...samePlanProps} wrapperStyles={planStyles} />
                        </SwiperSlide>)}
                    {isResponsive ? (
                        <NextPrevSlider wrapperStyles={nextPrevStyles} />
                    ) : ""}
                </SwiperWrapper>
            </div>
            <div className={`flex items-center gap-12 justify-center ${isResponsive ? "max-[670px]:hidden" : ""}`}>
                {plans.map((item) => <ShopifyPlan {...item} key={item.id} {...samePlanProps} />)}
            </div>
        </div>
    )
}

export default ShopifyPlansCont