
import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";
import Upgrade from "./components/ManagePayments/Upgrade";

const Billing = () => {
  const { pathname } = useLocation()
  const [, setCookie] = useCookies(["upCampaign"]);
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign);
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const user = useDecodeToken();
  const { data } = useGetUserPlanQuery(null);
  const ebayPlan = data?.result.find((item) => !item.isShopify)
  const isCanceled = ebayPlan?.isCanceled;
  const paymentStatus = ebayPlan?.status || 0;
  const isTrial = /^true$/i.test(user.active_trail);

  useEffect(() => {
    setCookie("upCampaign", isUpcampaign ? "true" : "false")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectPlan = (newVal: TSelectedPlan) => {
    dispatch(onSelectPlan(newVal));
    const redirLink = PATH.managementPanel[((paymentStatus === 0 || isCanceled) && !isTrial) ? "subscriptionPurchase" : "subscriptionUpgrade"]
    navigate(makeQueryParams(redirLink, {
      type: newVal.type,
      ...(newVal.skip && { skip: true })
    }));
  };

  return <>
    <Header
      className="!mb-5 max-xl:!flex-row"
      leftContent={
        <HeaderTab
          title="Billing"
          isActive={true}
          className="!min-w-[65px]"
          handleClick={() => { }}
        />
      }
      rightContent={
        <HeaderHelpVideo
          videos={
            helpVideos.managePayments
          }
        />
      }
    />
    <Upgrade
      handleSelectPlan={handleSelectPlan}
      paymentStatus={paymentStatus}
    />
  </>

}

export default Billing