import { FC } from "react";
import DaysCounter from "./DaysCounter";


interface Props {
    className?: string;
    activePlans: "shopify" | "ebay";
    fromRegFunnel?: boolean;
    isDashboardLayout?: boolean;
    period?: string;
    pulsing?: boolean;
}
const PlansDiscountTxt: FC<Props> = ({ className, isDashboardLayout, fromRegFunnel = false, pulsing }) => {
    return (
        <div>
            <div className={`flex gap-4 justify-center items-center max-sm:flex-col ${fromRegFunnel ? "" : "flex-col"} ${className}`}>
                <div className="flex items-center gap-4 max-md:flex-col">
                    <h6 className={`text-[20px] text-[#5EFFD8] font-bold border border-solid border-[#fff] bg-black rounded-[8px] leading-[42px] px-[15px] text-center
                        shadow-[0px_0px_59.451px_0px_#F93BE6,_0px_0px_33.972px_0px_#F93BE6,_0px_0px_19.817px_0px_#F93BE6,_0px_0px_9.909px_0px_#F93BE6,_0px_0px_2.831px_0px_#F93BE6,_0px_0px_1.416px_0px_#F93BE6]
                        ${isDashboardLayout ? "max-sm:text-[16px] max-sm:leading-[28px] max-sm:py-[5px] max-sm:min-w-[270px]" : "max-sm:leading-[28px] max-sm:py-[7px] "}`}>
                        {fromRegFunnel ? "Get Started for Half Price!" : <span><span className="text-[#F83BE5]">CYBER MONDAY</span> MEGA DEAL</span>}</h6>
                    <p className={`${fromRegFunnel ? "hidden" : ""} text-[20px] font-medium max-lg:hidden`}>Don’t Miss Out - Claim Now!</p>
                </div>
                <div className={`flex items-center gap-6 max-sm:gap-4 max-sm:flex-col`}>
                    <h6 className={`${fromRegFunnel ? "hidden" : ""} font-bold text-[24px] max-sm:text-lg`}>50% Off All Plans!</h6>
                    <DaysCounter pulsing={pulsing} />
                </div>
            </div>
        </div>



    )
}

export default PlansDiscountTxt;