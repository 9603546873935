import { PlansType, TSelectedPlan } from "types/plans";
import PlanFooterItem from "./PlanFooterItem";

interface Props {
  plans: PlansType;
  showCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  isPayNow?: boolean;
}


const PlanComparisonFooter = ({
  plans,
  selectedPlan,
  handleSelectPlan,
  fromRegistration = false,
  isPayNow,
}: Props) => {
  const showTwoPlans = plans.planList.length === 2;
  const mobilePlan = plans.planList.find((plan) => plan.planId === selectedPlan);
  const sameProps = {
    handleSelectPlan,
    fromRegistration,
    period: plans.period,
    isPayNow,
    showTwoPlans
  }
  return (
    <div className="max-w-[1220px] w-full">
      <div className={`grid max-lg:grid-cols-1 max-lg:hidden ${showTwoPlans ? "grid-cols-3" : "grid-cols-4"}`}>
        <div />
        {plans.planList.map((plan, i) => <PlanFooterItem
          key={plan.planId}
          plan={plan}
          {...sameProps} />)}
      </div>

      <div className="lg:hidden">
        {mobilePlan && (
          <PlanFooterItem
            plan={mobilePlan}
            {...sameProps} />
        )}
      </div>
    </div>
  )
}

export default PlanComparisonFooter;
