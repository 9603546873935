

import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Controller, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

interface Props {
    onActiveChange?: (idx: number) => void;
    children: ReactNode;
    controller?: any;
    setController?: Dispatch<SetStateAction<any>>;
    space?: number;
    spaceOnMobile?: number;
}
const SwiperWrapper: FC<Props> = ({ onActiveChange, children, controller, setController, spaceOnMobile = 16, space = 32 }) => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Controller]}
            pagination={{
                clickable: true,
                el: ".pricing-progressbar",
                type: "progressbar",
            }}
            controller={{ control: controller }}
            onSwiper={setController}
            centeredSlides={true}
            initialSlide={1}
            autoHeight={false}
            spaceBetween={spaceOnMobile}
            swiperSlide
            breakpoints={{
                670: {
                    spaceBetween: space
                },
                500: {
                    spaceBetween: spaceOnMobile,
                },
                450: {
                    slidesPerView: "auto",
                },
            }}
            className="mySwiper"
            slidesPerView="auto"
            onSlideChange={(swiper: any) => {
                if (onActiveChange) {
                    onActiveChange(swiper.realIndex as number);
                }
            }}
        >
            {children}
        </Swiper>
    )
}

export default SwiperWrapper