import { plansComparison } from "assets/data/plans/plans";
import NextPrevSlider from "components/Plans/NextPrevSlider";
import PlansCardB from "components/Plans/PlanCardB";
import SwiperWrapper from "components/SwiperWrapper";
import { FC } from "react";
import { SwiperSlide } from "swiper/react";
import { PlanList, PlansType, TSelectedPlan } from "types/plans";
import PlansDetailedB from "./PlansDetailedB";
import Trusted from "./Trusted";

interface Props {
    plans: PlansType;
    handleSelectPlan: (plan: TSelectedPlan) => void;
}
interface PlanProps {
    plan: PlanList;
    handleSelectPlan: (plan: TSelectedPlan) => void;
    period: string
}

const PlanWithComparison: FC<PlanProps> = ({ plan, period, handleSelectPlan }) => {
    return (
        <div className="flex flex-col mb-10" >
            <PlansCardB
                wrapperClassName={` ${plan.highlight ? "" : " mt-2.5"} mb-[154px] max-lg:mb-[188px] max-sm:mb-[246px] mx-auto`}
                isMonthly={period === "monthly"}
                name={plan.planName}
                description={plan.planDescription}
                discountPrice={plan.planDiscountPrice}
                isDiscount={plan.isDiscount}
                handleSelectPlan={handleSelectPlan}
                type={plan.type}
                price={plan.planPrice}
                id={plan.planId}
                skipAmount={plan.skipAmout}
                popularBadge={plan.highlight}
            />
            <PlansDetailedB
                wrapperClassName={`${plan.highlight ? "-mt-2.5" : ""} mx-auto`}
                name={plan.planName}
                price={plan.planPrice}
                skipAmount={plan.skipAmout}
                id={plan.planId}
                type={plan.type}
                handleSelectPlan={handleSelectPlan}
                isPopular={plan.highlight}
                discountedPrice={plan.planDiscountPrice}
                period={period}
                isEbay
                features={plansComparison.map((item) => ({
                    Icon: item.Icon,
                    title: item.title,
                    subtitle: item.description,
                    width: item.width,
                    height: item.height,
                    pro: item.pro,
                    proPlus: item.proPlus,
                    enterprise: item.enterprise,
                }))}
            />
        </div>
    )
}
const RegistrationFunnelBEbayPlans: FC<Props> = ({ plans, handleSelectPlan }) => {

    return <div className="max-w-[682px] w-full mx-auto relative pt-2 max-lg:pt-0">
        <div className="w-full lg:hidden">
            <SwiperWrapper>
                {plans.planList.map((plan) => (
                    <SwiperSlide className="h-auto max-w-[310px]" content="fit" key={plan.planId}>
                        <PlanWithComparison plan={plan} key={plan.planId} handleSelectPlan={handleSelectPlan} period={plans.period} />
                    </SwiperSlide>)
                )}
            </SwiperWrapper>
        </div>
        <div className="w-full flex justify-between max-lg:hidden">
            {plans.planList.map((plan) => (
                <PlanWithComparison plan={plan} key={plan.planId} handleSelectPlan={handleSelectPlan} period={plans.period} />
            )
            )}
        </div>
        <div className={`w-full flex flex-col absolute left-0 ${plans.period === "yearly" ? "top-[321px]" : "top-[337px]"}`}>
            <NextPrevSlider />
            <Trusted />
        </div>
    </div>

}

export default RegistrationFunnelBEbayPlans;