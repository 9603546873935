import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

interface ItemProps {
    price: number;
    name: string;
    id: string;
    isYearly: boolean;
    skipAndSave: number;
    type: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    discountedPrice: number;
    fromNoSub?: boolean;
    selectedPlan?: "monthly" | "yearly"
}
interface Props {
    isRegFunnel?: boolean;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    selectedPlan?: "monthly" | "yearly"
    fromNoSub?: boolean;
}

const FooterItemContent: FC<ItemProps> = ({ price, type, name, skipAndSave, isYearly, selectedPlan, discountedPrice, handleSelectPlan, fromNoSub, isPayNow }) => {
    const navigate = useNavigate();
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const hasNoDiscount = false;
    const isUpgrade = isYearly && user.shopify_package_name === "ShopProM";
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const isCurrent = (isYearly && user.shopify_package_name === "ShopProY") || (!isYearly && user.shopify_package_name === "ShopProM");
    const skipDollarStyles = `mb-2 bg-white mx-auto max-sm:max-w-[100%]`
    const skipTitleStyles = "!text-base dark:text-darkBlue dark:group-hover:text-white"
    const skipDollarTrial = isPayNow ? "Pay Now" : "Start $1 Trial Now";
    const isCurrentTrial = isTrial && isCurrent
    const handleClick = (isSkip?: boolean) => {
        const plan = {
            type: type,
            planName: name,
            planPrice: price,
            skip: isSkip || false

        }
        if (handleSelectPlan) {
            handleSelectPlan(plan);
        } else {
            navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, {
                isShopify: true,
                type: plan.type,
                ...(isSkip && { skip: true })
            }));
            dispatch(onSelectPlan(plan));

        }
    }
    return isCurrent ? <div className="text-center text-sm font-medium">{isCurrentTrial ? "Your current $1 Trial plan" : "This is your Current Plan"}</div> : (
        <div className="flex flex-col">
            <div className={`flex justify-between mb-2  max-md:mb-1 ${isYearly ? "max-md:mb-1 items-end" : "items-center"}`}>
                <div>
                    <h5 className="text-xl font-bold max-md:text-center max-md:hidden">{name}</h5>
                    <div className={`flex items-center max-md:flex-col items-start justify-between text-xs  `}>
                        <h6 className="text-lg max-md:mx-auto font-bold">{formatCurrency(hasNoDiscount ? price : discountedPrice)}<span className="font-normal">/mo</span></h6>
                    </div>
                </div>
                {hasNoDiscount ? "" :
                    <div className="max-md:hidden">
                        <span className={`font-bold text-[#24921A] block ${isYearly ? "hidden" : ""}`}>
                            50% Off
                        </span>
                        {isYearly ? (
                            <div className="flex items-center">
                                <span className={`text-sm text-[#FF6A00] font-medium leading-[30px]`}>Save ${discountedPrice && Math.round((price - discountedPrice) * 12)}!</span>
                                <div className="flex w-[30px] h-[30px] items-center justify-center">
                                    <Checkmark className="fill-smoothGreen" />
                                </div>
                            </div>
                        ) : <span className="block text-sm font-medium">for 1st month</span>}
                    </div>

                }
            </div>


            {isUpgrade || isTrial ? (
                <Button title={isTrial ? "Change" : "Upgrade Now"} className={`mb-[15px] bg-white mx-auto mt-2 max-sm:!mb-1`} redirect={PATH.shopify.upgrade} isLink titleClassName={skipTitleStyles} color="smoothGreen" height="h-11" />
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <Button title={skipDollarTrial} className={skipDollarStyles} titleClassName={skipTitleStyles} color="smoothGreen" height="h-11" handleClick={() => handleClick()} />
                    {isPayNow ? "" : (
                        <button className={`text-center underline text-xs ${fromNoSub ? "max-md:!text-smoothGreen" : ""} ${isYearly ? "text-electricBlue md:text-smoothGreen " : "text-electricBlue"}`}
                            onClick={() => handleClick(true)}> Skip Trial {skipAndSave === 0 ? "Now!" : `and Save ${formatCurrency(skipAndSave)}`}</button>
                    )}
                </div>
            )}
        </div>
    )
}
const ShopifyPlanComparisonFooter: FC<Props> = ({ isRegFunnel, handleSelectPlan, selectedPlan, isPayNow, fromNoSub }) => {
    const user = useDecodeToken();
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const currentYearly = !isTrial && user.shopify_package_name === "ShopProY";
    const currentMonthly = !isTrial && user.shopify_package_name === "ShopProM";
    const currentMonthlyTrial = isTrial && user.shopify_package_name === "ShopProM";
    const currentYearlyTrial = isTrial && user.shopify_package_name === "ShopProY";
    const cardStyles = `w-full relative rounded-[15px]  pl-4 pr-[13px] max-md:bg-transparent max-md:px-1 md:-ml-[1px] min-[670px]:ml-[0px] max-[670px]:pl-[23px] max-[670px]:pr-[23px]`;
    const regStyles = "h-[202px]";
    const currentPlanStyles = "h-[106px] -top-[31px]";
    const payNowStyles = "h-[180px] max-md:h-[90px]";
    const hideOnMobile = "max-[670px]:hidden";
    const hideOnDowngrade = "invisible"
    const itemProps = {
        isRegFunnel,
        handleSelectPlan,
        isPayNow,
        fromNoSub,
        selectedPlan
    }

    return (
        <div className={`grid grid-cols-3 max-[670px]:grid-cols-1`}>
            <div className="max-[670px]:hidden" />
            <div className={`bg-secondary text-darkBlue pt-[53px]  
                ${cardStyles} ${selectedPlan !== "monthly" && hideOnMobile}  ${currentYearly && hideOnDowngrade} 
                ${(currentMonthly || currentMonthlyTrial) ? currentPlanStyles : `-top-[45px] 
                ${(isPayNow || currentYearlyTrial) ? payNowStyles : regStyles} `} 
            `}>
                <FooterItemContent {...shopifyPlans[0]} {...itemProps} />
            </div>
            <div
                className={`border-solid border-2 pt-[51px] max-md:text-darkBlue bg-darkBlue max-md:border-none 
                ${cardStyles} ${selectedPlan !== "yearly" && hideOnMobile}
                ${currentYearly || currentYearlyTrial ? `${currentPlanStyles} bg-secondary text-darkBlue !border-secondary` :
                        `-top-[45px] max-md:-top-[43px] text-white border-smoothGreen 
                ${(isPayNow || currentMonthlyTrial) ? payNowStyles : currentMonthly ? "h-[184px]" : regStyles}`} 
                `}>
                <FooterItemContent {...shopifyPlans[1]} {...itemProps} />
            </div>
        </div>
    )
}

export default ShopifyPlanComparisonFooter